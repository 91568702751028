<template>
  <div>
    <b-row v-if="showBanner">
      <b-col cols="12">
        <AnnouncementBanner icon="InfoIcon" :message="announcementMessage" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h1>
          <b>{{ $t('hello') }}</b
          >, {{ user.name }}
        </h1>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-md-7">
        <b-overlay :show="show">
          <div class="card">
            <div class="card-body">
              <h3>
                <b>{{ $t('guidelines') }}</b>
              </h3>
              <p>
                {{ $t('guidelines_message') }} {{ $t('click_here') }}
                <b-link class="text-danger" @click="handleDownloadTutorial">
                  <feather-icon icon="DownloadIcon" />
                  {{ $t('download_tutorial') }}
                </b-link>
              </p>
            </div>
          </div>
        </b-overlay>

        <b-overlay :show="show">
          <div class="card">
            <div class="card-body">
              <h3>
                <b>Memo (Update on B2B & B2C Orders (Effective 1/10/2024)</b>
              </h3>
              <p>
                Dear Clients, Please take note of the following updates regarding all B2B & B2C orders, effective from 1/10/2024:
                <br />
                <br />
                1) Weight: The Maximum weight for all B2B & B2C orders is 15kg. Any weight that exceeds will be considered overweight and will be double charged.
                <br />
                <br />
                2) Price:
                <br />
                - B2C: The price for local area will be RM20.00 and outstation will be RM30.00.
                <br />
                - B2B: The price will remain RM15 except for east coast until further notice.
                <br />
                <br />
                3) MOQ: A minimum order of 10 boxes is required for all B2B orders.
                <br />
                <br />
                Thank you for your understanding and cooperation.
              </p>
              <a
                href="https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/MEMO_B2B_AND_B2C_EFFECTIVE_01102024.pdf"
                target="_blank"
                class="text-left"
                style="text-decoration: underline; display: block; margin-top: 1rem;"
              >
                Download Memo
              </a>
            </div>
          </div>
        </b-overlay>

        <div class="card">
          <div class="card-body">
            <h3>
              <b>{{ $t('document_checklist_upon_delivery') }}</b>
            </h3>
            <p>{{ $t('document_checklist_message_1') }}</p>
            <p>{{ $t('document_checklist_message_2') }}</p>
            <p>{{ $t('document_checklist_message_3') }}</p>
            <p>{{ $t('document_checklist_message_4') }}</p>
          </div>
        </div>
        <div
          class="card"
          :style="
            `background:url('` +
            require('@/assets/images/bg/block.png') +
            `');background-size:cover;background-repeat: no-repeat;background-position: center center;`
          "
        >
          <div class="card-body">
            <h2 class="text-danger">
              <b>{{ $t('today') }}</b
              ><br />{{
                $t('count_deliveries', { '1': today ? today.length : 0 })
              }}
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title mb-0">
                  <b>{{ $t('order_of_the_day') }}</b>
                </h3>
                <hr />
                <schedule-list :orders="today" />
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <div class="card-body">
                <h3 class="card-title mb-0">
                  <b>{{ $t('scheduled_delivery') }}</b>
                </h3>
                <hr />
                <b-alert
                  v-if="Object.keys(orders).length < 1"
                  class="my-1"
                  variant="info"
                  show
                >
                  <div class="alert-body">
                    {{ $t('no_scheduled_delivery_found') }}
                  </div>
                </b-alert>
                <div v-for="(order, date, index) in orders" v-else :key="date">
                  <h4>{{ date | moment('DD-MM-YYYY') }}</h4>
                  <schedule-list :orders="order" />
                  <hr v-if="index !== Object.keys(orders).length < 1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
          <div class="card-body text-center">
            <h3>{{ $t('my_profile') }}</h3>
            <br />
            <div class="mb-2">
              <b-avatar
                :src="`${$s3URL}uploads/user/${user.pic}`"
                size="12rem"
              />
              <h3 class="my-2">
                {{ user.name }}
              </h3>
              <b-button type="button" variant="outline-primary" to="/profile">{{
                $t('view_full_profile')
              }}</b-button>
            </div>
            <hr />
            <div class="text-left">
              <b>{{ $t('your_last_transaction') }}</b>
              <b-list-group class="mt-2">
                <b-list-group-item
                  v-if="!transactions.length"
                  class="flex-column align-items-start"
                >
                  <div class="d-flex w-100 justify-content-center">
                    <h6 class="mb-0 text-info">
                      {{ $t('no_transactions_found') }}
                    </h6>
                  </div>
                </b-list-group-item>
                <b-list-group-item
                  v-for="transaction in transactions"
                  :key="transaction.id"
                  class="flex-column align-items-start"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <div>
                      <h5 class="mb-0">
                        {{ $helpers.formatTotal(transaction.price / 100) }}
                      </h5>
                      <a
                        :href="transaction.receipt"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ $t('view_details') }}</a
                      >
                    </div>
                    <small class="text-right"
                      >{{ transaction.order_id }}<br />{{
                        transaction.date_added | moment('DD-MM-YYYY')
                      }}</small
                    >
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for special announcement -->
    <b-modal
      v-model="showSpecialModal"
      hide-footer
      hide-header
      size="lg"
      centered
    >
      <div class="my-1">
        <h2 class="text-primary text-center">{{ modalTitle }}</h2>
        <p class="text-center">{{ modalDescription }}</p>
        <iframe
          :src="pdfUrl"
          width="100%"
          height="500px"
          frameborder="0"
        ></iframe>
        <a
          href="https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/MEMO_B2B_AND_B2C_EFFECTIVE_01102024.pdf"
          target="_blank"
          class="text-left"
          style="text-decoration: underline; display: block; margin-top: 1rem"
          >Download Memo</a
        >
        <div style="display: flex; justify-content: flex-end; margin-top: 1rem">
          <b-button variant="primary" @click="confirmSpecialModal"
            >Close</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import { ScheduleList, AnnouncementBanner } from '@/components'
import { BAlert, BModal, BButton } from 'bootstrap-vue'

export default {
  name: 'Dashboard',
  components: {
    ScheduleList,
    AnnouncementBanner,
    BAlert,
    BModal,
    BButton
  },
  data() {
    return {
      announcementMessage:
        "Announcement! We've upgraded the system to version 4.2. Enjoy the latest convenient! Have a nice day from Team Golog!",
      show: false,
      type: 1,
      today: [],
      orders: [],
      transactions: [],
      user: { name: 'Name' },
      showBanner: false,
      showSpecialModal: false,
      pdfUrl: '',
      modalTitle: 'Update on B2B and B2C Orders',
      modalDescription: 'Effective on 1st of October 2024'
    }
  },
  created() {
    this.user = getUserData()
    this.getScheduledOrders()
    this.getLastTransaction()
    const expiredDate = new Date(Date.now() + 4 * 86400000)
    const today = new Date(Date.now())
    if (today < expiredDate) {
      this.showBanner = true
      setTimeout(() => {
        this.showBanner = false
      }, 5000)
    }
    this.checkSpecialModal()
  },
  methods: {
    getLastTransaction() {
      const self = this
      this.$http
        .get('last_transaction')
        .catch((err) => {
          console.error(err)
        })
        .then((response) => {
          self.transactions = response.data.data
        })
    },
    getScheduledOrders() {
      const self = this
      this.$http
        .get('scheduledorders')
        .catch((err) => {
          console.error(err)
        })
        .then((response) => {
          const orders = response.data.data
          self.orders = Object.keys(orders)
            .sort()
            .reduce((obj, key) => {
              /* eslint-disable-next-line */
              obj[key] = orders[key].filter((order) =>
                [2, 3, 4, 5, 6, 7].includes(order.status)
              )
              return obj
            }, {})
          /* eslint-disable-next-line */
          const filtered = Object.entries(self.orders).filter(
            ([key, value]) => value.length > 0
          )
          self.orders = Object.fromEntries(filtered)
          self.today = orders[self.$moment().format('YYYY-MM-DD')]
        })
    },
    async handleDownloadTutorial() {
      this.show = true
      const response = await this.$http.get(`/download-tutorial/${this.type}`, {
        responseType: 'blob'
      })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        'B2BPRO 3.1 User Tutorial (English).pdf'
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      this.show = false
    },
    checkSpecialModal() {
      const hasConfirmed = localStorage.getItem('specialModalConfirmed')

      if (!hasConfirmed) {
        this.pdfUrl =
          'https://docs.google.com/gview?url=https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/MEMO_B2B_AND_B2C_EFFECTIVE_01102024.pdf&embedded=true' // Replace with your PDF URL
        this.showSpecialModal = true
      }
    },
    confirmSpecialModal() {
      localStorage.setItem('specialModalConfirmed', 'true')
      this.showSpecialModal = false
    },
    async downloadPdf() {
      try {
        const response = await fetch(
          'https://gologspace.sgp1.cdn.digitaloceanspaces.com/misc_files/MEMO_B2B_AND_B2C_EFFECTIVE_01102024.pdf'
        )
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'MEMO_B2B_AND_B2C_EFFECTIVE_01102024.pdf'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error('Error downloading the PDF:', error)
      }
    }
  }
}
</script>
<style scoped>
</style>